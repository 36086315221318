import { ContentSearchItem } from "../search/SearchRequest";

export enum ActivityType {
    None = 0,
    ContentEdited = 1,
    WorkflowAssigned = 2,
    WorkflowEndUtcChanged = 3,
    ContentPublished = 5,
    ContentCreated = 6,
    WorkflowStatusChanged = 7,
    UserAssignedTo = 8,
    WorkflowStartDateChanged = 9,
    LinkAddedToTarget = 11,
    LinkAddedToSource = 12,
    DraftEdited = 13,
    EvidenceAdded = 15,
    EvidenceRemoved = 16,
    LinkRemovedFromTarget = 17,
    LinkRemovedFromSource = 18,
    WorkflowDraftStatusChanged = 19,
    UserInvited = 20,
    UserEdited = 21,
    UserCreated = 22,
    GroupCreated = 23,
    GroupEdited = 24,
    UserRegistered = 25,
    UserAddedToGroup = 26,
    UserRemovedFromGroup = 27,
    WorkflowExplanationEdited = 28,
    WorkflowDraftExplanationEdited = 29,
    TaskInstanceGenerated = 30,
    ReviewItemApproved = 31,
    ReviewItemUnapproved = 32,
    ReviewerAdded = 33,
    ReviewerRemoved = 34,
    SpaceCreated = 35,
    SpaceEdited = 36,
    EntityCreated = 37,
    ContentAddedToReview = 39,
    ReviewCreated = 40,
    DraftDiscarded = 41,
    AuditCreated = 42,
    AuditItemsUpdated = 43,
    AuditItemDeleted = 44,
    AuditDeleted = 45,
    ItemSpaceEdited = 46,
    RoleCreated = 47,
    RoleEdited = 48,
    StandardEdited = 49,
    ClientEntityCreated = 50,
    ReviewPublished = 51,
    ItemDeleted = 52,
    ControlImplemented = 53,
    CommentDeleted = 54,
    CommentEdited = 55,
    CommentCreated = 56,
    CommentReplied = 57,
    CommentResolved = 58,
    CommentReopened = 59,
    IssueAdded = 60,
    WorkflowRiskChanged = 61,
    WorkflowCompleted = 62,
    PackageCreated = 63,
    PackageItemImported = 64,
    PackageEdited = 65,
    PackageUpgraded = 66,
    StandardCreated = 67,
    ValidationAdded = 68,
    WorkflowMaturityChanged = 72
}

export type Activity = {
    id: string;
    subjectId: string;
    localKey: number;
    type: ActivityType;
    data?: { [key: string]: any } | undefined;
    modifiedBy: string;
    activityUtc: string;
    item?: ContentSearchItem;
    targetItem?: ContentSearchItem;
};
