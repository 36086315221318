import "semantic-ui-less/semantic.less";
import React, { useEffect, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import GoogleTagManager from "shared/components/analytics/GoogleTagManager";
import Routes from "./Routes";
import { AppProvider, contexts } from "./AppProvider";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/browser";
import { configure } from "mobx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// TODO find a more specific home for this
import "react-datepicker/dist/react-datepicker.css";

// TODO rrules?
import { IntlProvider } from "react-intl";
import { getLocaleStrings, getLocaleCode, getSelectedLangauge } from "util/LocalizationProvider";

// register locales for react-datepicker
import "moment/locale/en-gb.js";
import "moment/locale/en-ca.js";
import { enGB } from "date-fns/locale/en-GB";
import { enCA } from "date-fns/locale/en-CA";
import { registerLocale } from "react-datepicker";

registerLocale("en-gb", enGB);
registerLocale("en-ca", enCA);

// don't allow state modifications outside actions
configure({ enforceActions: "observed" });

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeSend(e, hint) {
        const status = (hint?.originalException as any)?.response?.status;
        if (status === 401 || status === 403) {
            return null;
        }

        return e;
    }
});

// TODO: Need this to be a setting, since it changes in envs
const googleTagManagerContainerId = "GTM-P9WC99B";
const queryClient = new QueryClient();

const AppConsumer = observer(() => {
    useEffect(() => {
        document.title = "Aberrant";
    }, []);

    const session = useContext(contexts).session;

    const selectedLanguage = getSelectedLangauge(session);
    const localeStrings = getLocaleStrings(selectedLanguage);

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <React.Fragment>
                    <ToastContainer />
                    <GoogleTagManager containerId={googleTagManagerContainerId} />
                    {session?.loader.succeeded && (
                        <IntlProvider
                            messages={localeStrings}
                            locale={getLocaleCode(selectedLanguage)}
                            defaultLocale="en"
                        >
                            <Routes />
                        </IntlProvider>
                    )}
                    {session?.loader.error && (
                        <div style={{ padding: "1em" }}>
                            Error loading session (
                            <button className="ui link" onClick={() => window.location.reload()}>
                                refresh
                            </button>
                            )
                        </div>
                    )}
                </React.Fragment>
            </BrowserRouter>
        </QueryClientProvider>
    );
});

export default () => {
    return (
        <AppProvider>
            <AppConsumer />
        </AppProvider>
    );
};
