import React from "react";
import { Controller, ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import PhoneInput, { Country, isPossiblePhoneNumber } from "react-phone-number-input/input";
import { Form } from "semantic-ui-react";

type PhoneNumberFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
    label: string;
    country: Country;
};

export const PhoneNumberField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    label,
    country,
    ...controllerProps
}: PhoneNumberFieldProps<TFieldValues, TName>) => {
    return (
        <Controller
            {...controllerProps}
            rules={{
                validate: (value) => {
                    if (!value?.length) {
                        return "Phone number is required";
                    }
                    const isValid = isPossiblePhoneNumber(value);
                    if (!isValid) {
                        return "Invalid phone number";
                    }
                }
            }}
            render={({ field: { value, onChange }, fieldState }) => (
                <PhoneInput
                    inputComponent={Form.Input}
                    value={value}
                    country={country}
                    international={true}
                    withCountryCallingCode={true}
                    onChange={onChange}
                    label={label}
                    id="phone"
                    error={fieldState.error && { content: fieldState.error.message }}
                />
            )}
        />
    );
};
