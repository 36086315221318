import { contexts } from "AppProvider";
import { useContext, useMemo } from "react";
import { useHasPermission } from "shared/components/navigation/useHasPermission";
import { Permission } from "shared/models/Permission";
import { EntityType } from "shared/models/search/SearchRequest";

export const useRemoteAgentsUserPermissions = () => {
    const hasPermission = useHasPermission();
    const session = useContext(contexts).session;

    const canSeeAgents =
        session?.entity.entityTypeId === EntityType.Business ||
        session?.entity.entityTypeId === EntityType.InternalFunction;

    return useMemo(
        () => ({
            canSeeAgentsWidget: canSeeAgents,
            canSeeAgentsPage: canSeeAgents && hasPermission({ permissions: [Permission.ManageRemoteAgent] }),
            canCreateAgent: hasPermission({ permissions: [Permission.ManageRemoteAgent] }),
            canUpdateAgent: hasPermission({ permissions: [Permission.ManageRemoteAgent] }),
            canDownload: hasPermission({ permissions: [Permission.ManageRemoteAgent] }),
            canDeleteAndRestore: hasPermission({ permissions: [Permission.ManageRemoteAgent] })
        }),
        [canSeeAgents, hasPermission]
    );
};
