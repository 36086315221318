import { ActivityType } from "shared/models/activities/Activity";
import ItemLink from "stack/links/ItemLink";
import AssigneeBadge from "widgets/items/AssigneeBadge";
import DateBadge from "widgets/items/DateBadge";
import StatusBadge from "widgets/items/StatusBadge";
import React from "react";
import { ActivityRenderer, AtTime } from "./ActivityPlugin";
import { ContentSearchItem } from "shared/models/search/SearchRequest";
import Badge from "widgets/items/Badge";
import { DraftStatus, ItemType } from "shared/models/Item";
import { WorkflowStatusType } from "shared/models/workflow/Workflow";
import getSession from "util/getSession";
import moment from "moment";

export const OptionalItemLink = (item: ({ name: string } | ContentSearchItem) & { showStatus?: boolean }) => {
    if ((item as ContentSearchItem).itemId) {
        const searchItem = item as ContentSearchItem;
        if (searchItem.draftStatus === DraftStatus.IsDeleted) {
            return (
                <span style={{ textDecoration: "line-through" }}>
                    {searchItem.displayNumber && <span style={{ fontWeight: 500 }}>{searchItem.displayNumber} </span>}
                    {item.name}
                </span>
            );
        }

        let dueDate, overdue;
        if (item.showStatus) {
            dueDate = moment(searchItem.endUtc);
            overdue = dueDate.isBefore(new Date()) && dueDate.isBefore(moment.utc(searchItem.completedUtc));
        }
        return (
            <ItemLink item={searchItem}>
                {searchItem.displayNumber && <span style={{ fontWeight: 500 }}>{searchItem.displayNumber} </span>}
                {item.name}
                {item.showStatus && (
                    <>
                        {" "}
                        {searchItem.endUtc && <DateBadge endUtc={searchItem.endUtc} overdue={overdue} />}
                        <StatusBadge status={searchItem.status} />
                    </>
                )}
            </ItemLink>
        );
    } else {
        return <>{item.name}</>;
    }
};

// Subject {performed activity} at time.
// {Activity performed} by subject at time.

const activities: ActivityRenderer[] = [
    [
        ActivityType.ContentEdited,
        ({ item }) => (
            <>
                <>
                    edited <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Edited</>
    ],
    [
        ActivityType.ItemDeleted,
        ({ item }) => (
            <>
                <>
                    deleted <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Deleted</>
    ],
    [
        ActivityType.WorkflowStartDateChanged,
        ({ data, item }) => (
            <>
                changed the effective date on <OptionalItemLink name="an item" {...item} /> to{" "}
                <DateBadge endUtc={data?.startDate} />
            </>
        ),
        ({ data }) => (
            <>
                Effective date changed to <DateBadge endUtc={data?.startDate} />{" "}
            </>
        )
    ],
    [
        ActivityType.ControlImplemented,
        ({ data, item }) =>
            !data?.completedUtc ? (
                <>
                    unimplemented <OptionalItemLink name="an item" {...item} />
                </>
            ) : (
                <>
                    implemented <OptionalItemLink name="an item" {...item} />
                </>
            ),
        ({ data }) => (!data?.completedUtc ? <>Unimplemented</> : <>Implemented</>)
    ],
    [
        ActivityType.WorkflowEndUtcChanged,
        ({ data, item }) =>
            !data?.dueDate ? (
                <>
                    removed the due date from <OptionalItemLink name="an item" {...item} />
                </>
            ) : (
                <>
                    changed the due date on <OptionalItemLink name="an item" {...item} /> to{" "}
                    <DateBadge endUtc={data?.dueDate} />
                </>
            ),
        ({ data }) =>
            !data?.dueDate ? (
                <>Due date removed</>
            ) : (
                <>
                    Due date changed to <DateBadge endUtc={data?.dueDate} />{" "}
                </>
            )
    ],
    [
        ActivityType.WorkflowAssigned,
        ({ data, item }) => (
            <>
                assigned <OptionalItemLink name="an item" {...item} /> to{" "}
                <AssigneeBadge assigneeId={data?.assigneeId} />
            </>
        ),
        ({ data }) => (
            <>
                Assigned to <AssigneeBadge assigneeId={data?.assigneeId} />
            </>
        )
    ],
    [
        ActivityType.WorkflowRiskChanged,
        ({ data, item }) => (
            <>
                changed the CVS on <OptionalItemLink name="an item" {...item} />
            </>
        ),
        ({ data }) => <>CVS changed</>
    ],
    [
        ActivityType.WorkflowMaturityChanged,
        ({ data, item }) => (
            <>
                changed the maturity on <OptionalItemLink name="an item" {...item} />
            </>
        ),
        ({ data }) => <>Maturity changed</>
    ],
    [
        ActivityType.ContentPublished,
        ({ item, data, targetItem }) => (
            <>
                <>
                    <StatusBadge status={WorkflowStatusType.Published} />
                    {data?.version > 0 && item?.type !== ItemType.Standard && (
                        <>
                            <OptionalItemLink {...targetItem} name={"Version " + data?.version} /> of{" "}
                        </>
                    )}
                    <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        ({ data, targetItem }) => (
            <>
                {data?.version > 0 ? (
                    <>
                        <OptionalItemLink {...targetItem} name={"Version " + data?.version} />{" "}
                        <StatusBadge status={WorkflowStatusType.Published} />
                    </>
                ) : (
                    <StatusBadge status={WorkflowStatusType.Published} />
                )}
            </>
        )
    ],
    [
        ActivityType.ReviewPublished,
        ({ item }) => (
            <>
                <>
                    <StatusBadge status={WorkflowStatusType.Published} />
                    <OptionalItemLink name="a review" {...item} />
                </>
            </>
        ),
        () => (
            <>
                <StatusBadge status={WorkflowStatusType.Published} />
            </>
        )
    ],
    [
        ActivityType.ContentCreated,
        ({ item }) => (
            <>
                <>
                    created <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Created</>
    ],
    [
        ActivityType.WorkflowStatusChanged,
        ({ item, data }) => (
            <>
                <>
                    changed <OptionalItemLink name="an item" {...item} /> to{" "}
                    <StatusBadge status={data?.workflowStatusType} />
                </>
            </>
        ),
        ({ data }) => (
            <>
                Changed to <StatusBadge status={data?.workflowStatusType} />
            </>
        )
    ],
    [
        ActivityType.WorkflowStatusChanged,
        ({ item, data }) => (
            <>
                <>
                    changed <OptionalItemLink name="an item" {...item} /> to{" "}
                    <StatusBadge status={data?.workflowStatusType} />
                </>
            </>
        ),
        ({ data }) => (
            <>
                Changed to <StatusBadge status={data?.workflowStatusType} />
            </>
        )
    ],
    [
        ActivityType.WorkflowDraftStatusChanged,
        ({ item, data }) => (
            <>
                <>
                    proposed changing <OptionalItemLink name="an item" {...item} /> to{" "}
                    <StatusBadge status={data?.workflowStatusType} />
                </>
            </>
        ),
        ({ data }) => (
            <>
                Proposed changing to <StatusBadge status={data?.workflowStatusType} />
            </>
        )
    ],
    // [ActivityType.LinkSource, () => <></>, () => <></>],
    // [ActivityType.LinkTarget, () => <></>, () => <></>],
    [
        ActivityType.DraftEdited,
        ({ item }) => (
            <>
                <>
                    edited a draft of <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Draft edited</>
    ],
    [
        ActivityType.EvidenceAdded,
        ({ item, targetItem }) => (
            <>
                <>
                    added <OptionalItemLink {...targetItem} name="evidence" /> to{" "}
                    <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        ({ targetItem }) => (
            <>
                <OptionalItemLink {...targetItem} name="Evidence" /> added
            </>
        )
    ],
    [
        ActivityType.EvidenceRemoved,
        ({ item, targetItem }) => (
            <>
                <>
                    removed <OptionalItemLink {...targetItem} name="evidence" /> from{" "}
                    <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        ({ targetItem }) => (
            <>
                <OptionalItemLink {...targetItem} name="Evidence" /> removed
            </>
        )
    ],
    [
        ActivityType.IssueAdded,
        ({ item, targetItem }) => (
            <>
                <>
                    added <OptionalItemLink {...targetItem} name="an issue" /> to{" "}
                    <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        ({ targetItem }) => (
            <>
                <OptionalItemLink {...targetItem} name="Issue" /> added
            </>
        )
    ],
    [
        ActivityType.WorkflowDraftExplanationEdited,
        ({ item }) => (
            <>
                <>
                    proposed an explanation for <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Explanation proposed</>
    ],
    [
        ActivityType.UserInvited,
        ({ subjectId }) => (
            <>
                invited <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} />
            </>
        ),
        () => <>Invited</>
    ],
    [
        ActivityType.UserEdited,
        ({ subjectId }) => (
            <>
                edited <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} />
            </>
        ),
        () => <>Edited</>
    ],
    [
        ActivityType.UserCreated,
        ({ subjectId }) => (
            <>
                created <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} />
            </>
        ),
        () => <>Created</>
    ],
    [
        ActivityType.GroupCreated,
        ({ subjectId }) => (
            <>
                created <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} />
            </>
        ),
        () => <>Created</>
    ],
    [
        ActivityType.GroupEdited,
        ({ subjectId }) => (
            <>
                edited <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} />
            </>
        ),
        () => <>Edited</>
    ],
    [ActivityType.RoleCreated, () => <>created a role</>, () => <>Created</>],
    [ActivityType.RoleEdited, () => <>edited a role</>, () => <>Edited</>],
    [ActivityType.ClientEntityCreated, () => <>created a new client</>, () => <>Created</>],
    [
        ActivityType.UserRegistered,
        ({ userName, time }) => (
            <>
                {userName} registered <AtTime>{time}</AtTime>.
            </>
        ),
        ({ time }) => (
            <>
                Registered <AtTime>{time}</AtTime>.
            </>
        ),
        true
    ],
    [
        ActivityType.UserAddedToGroup,
        ({ subjectId, data }) => (
            <>
                added <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} /> to{" "}
                <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId} />
            </>
        ),
        ({ data }) => (
            <>
                Added to <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId} />
            </>
        )
    ],
    [
        ActivityType.UserRemovedFromGroup,
        ({ subjectId, data }) => (
            <>
                removed <AssigneeBadge defaultName="Unknown" assigneeId={subjectId} /> from{" "}
                <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId} />
            </>
        ),
        ({ data }) => (
            <>
                Removed from <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId} />
            </>
        )
    ],
    [
        ActivityType.SpaceEdited,
        ({ item }) => (
            <>
                edited <OptionalItemLink name="a space" {...item} />
            </>
        ),
        () => <>Edited</>
    ],
    [
        ActivityType.TaskInstanceGenerated,
        undefined,
        ({ targetItem }) => (
            <>
                <OptionalItemLink {...targetItem} name="Instance" /> created
            </>
        )
    ],
    [
        ActivityType.ReviewItemApproved,
        ({ item, data, targetItem }) => (
            <>
                <Badge label="approved" background="#0c0" />
                {data?.version > 0 && item?.type !== ItemType.Standard ? (
                    <>
                        <OptionalItemLink {...targetItem} name={"Version " + data?.version} /> of{" "}
                    </>
                ) : (
                    <>
                        <OptionalItemLink {...targetItem} name="Changed" /> to{" "}
                    </>
                )}
                <OptionalItemLink name="an item" {...item} />
            </>
        ),
        ({ item, data, targetItem }) => (
            <>
                {data?.version > 0 && item?.type !== ItemType.Standard ? (
                    <>
                        <OptionalItemLink {...targetItem} name={"Version " + data?.version} />{" "}
                    </>
                ) : (
                    <>
                        <OptionalItemLink {...targetItem} name="Changed" /> to{" "}
                    </>
                )}
                <Badge label="approved" background="#0c0" />
            </>
        )
    ],
    [
        ActivityType.ReviewItemUnapproved,
        ({ item, data, targetItem }) => (
            <>
                <Badge label="unapproved" background="#ccc" />
                {data?.version > 0 && item?.type !== ItemType.Standard ? (
                    <>
                        <OptionalItemLink {...targetItem} name={"Version " + data?.version} /> of{" "}
                    </>
                ) : (
                    <>
                        <OptionalItemLink {...targetItem} name="changes" /> to{" "}
                    </>
                )}
                <OptionalItemLink name="an item" {...item} />
            </>
        ),
        ({ item, data, targetItem }) => (
            <>
                {data?.version > 0 && item?.type !== ItemType.Standard ? (
                    <>
                        <OptionalItemLink {...targetItem} name={"Version " + data?.version} />{" "}
                    </>
                ) : (
                    <>
                        <OptionalItemLink {...targetItem} name="changes" /> to{" "}
                    </>
                )}
                <Badge label="unapproved" background="#ccc" />
            </>
        )
    ],
    [
        ActivityType.ReviewerAdded,
        ({ item, targetItem, data }) => (
            <>
                added <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId || data?.entityUserId} /> to{" "}
                a <OptionalItemLink {...item} name="review" /> of <OptionalItemLink name="an item" {...targetItem} />
            </>
        ),
        ({ item, data }) => (
            <>
                Added <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId || data?.entityUserId} />
                as <OptionalItemLink {...item} name="a reviewer" />
            </>
        )
    ],
    [
        ActivityType.ReviewerRemoved,
        ({ item, targetItem, data }) => (
            <>
                removed <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId || data?.entityUserId} />{" "}
                from a <OptionalItemLink {...item} name="review" /> of{" "}
                <OptionalItemLink name="an item" {...targetItem} />
            </>
        ),
        ({ item, data }) => (
            <>
                Removed <AssigneeBadge defaultName="Unknown" assigneeId={data?.entityGroupId || data?.entityUserId} />
                as <OptionalItemLink {...item} name="a reviewer" />
            </>
        )
    ],
    [
        ActivityType.SpaceCreated,
        ({ item }) => (
            <>
                created <OptionalItemLink name="a space" {...item} />
            </>
        ),
        () => <>Created</>
    ],
    [
        ActivityType.SpaceEdited,
        ({ item }) => (
            <>
                edited <OptionalItemLink name="a space" {...item} />
            </>
        ),
        () => <>Edited</>
    ],
    [ActivityType.EntityCreated, () => <>joined Aberrant</>, () => <>Created</>],
    [
        ActivityType.ReviewCreated,
        ({ item }) => (
            <>
                created <OptionalItemLink name="a review" {...item} />
            </>
        ),
        () => <>Created</>
    ],
    [
        ActivityType.ContentAddedToReview,
        ({ item, targetItem }) => (
            <>
                added <OptionalItemLink name="an item" {...item} /> to{" "}
                <OptionalItemLink name="a review" {...targetItem} />
            </>
        ),
        ({ targetItem }) => (
            <>
                Added to <OptionalItemLink name="a review" {...targetItem} />
            </>
        )
    ],
    [
        ActivityType.LinkAddedToTarget,
        ({ item, targetItem }) => (
            <>
                linked <OptionalItemLink name="an item" {...item} /> to{" "}
                <OptionalItemLink name="an item" {...targetItem} />
            </>
        ),
        ({ item, targetItem }) => (
            <>
                Link from <OptionalItemLink name="an item" {...item} /> to{" "}
                <OptionalItemLink name="an item" {...targetItem} /> added
            </>
        )
    ],
    [
        ActivityType.LinkRemovedFromTarget,
        ({ item, targetItem }) => (
            <>
                removed link from <OptionalItemLink name="an item" {...item} /> to{" "}
                <OptionalItemLink name="an item" {...targetItem} />
            </>
        ),
        ({ item, targetItem }) => (
            <>
                Link from <OptionalItemLink name="an item" {...item} /> to{" "}
                <OptionalItemLink name="an item" {...targetItem} /> removed
            </>
        )
    ],
    [
        ActivityType.LinkAddedToSource,
        undefined,
        ({ targetItem }) => (
            <>
                Link from <OptionalItemLink name="an item" {...targetItem} /> added
            </>
        )
    ],
    [
        ActivityType.LinkRemovedFromSource,
        undefined,
        ({ targetItem }) => (
            <>
                Link from <OptionalItemLink name="an item" {...targetItem} /> removed
            </>
        )
    ],
    [
        ActivityType.DraftDiscarded,
        ({ item }) => (
            <>
                <>
                    discarded a draft of <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Draft discarded</>
    ],
    [
        ActivityType.ItemSpaceEdited,
        ({ item, targetItem, data }) => (
            <>
                changed the space of <OptionalItemLink name="an item" {...item} /> to{" "}
                <OptionalItemLink
                    name={data?.targetItemId === getSession().entityId ? "Global" : "a space"}
                    {...targetItem}
                />
            </>
        ),
        ({ targetItem, data }) => (
            <>
                Changed space to{" "}
                <OptionalItemLink
                    name={data?.targetItemId === getSession().entityId ? "Global" : "a space"}
                    {...targetItem}
                />
            </>
        )
    ],
    [
        ActivityType.CommentEdited,
        ({ item }) => (
            <>
                <>
                    edited a comment on <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Comment edited</>
    ],
    [
        ActivityType.CommentDeleted,
        ({ item }) => (
            <>
                <>
                    deleted a comment on <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Comment deleted</>
    ],
    [
        ActivityType.CommentReplied,
        ({ item }) => (
            <>
                <>
                    replied to a comment on <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Comment replied to</>
    ],
    [
        ActivityType.CommentCreated,
        ({ item }) => (
            <>
                <>
                    commented on <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Comment added</>
    ],
    [
        ActivityType.CommentResolved,
        ({ item }) => (
            <>
                <>
                    resolved a comment on <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Comment resolved</>
    ],
    [
        ActivityType.CommentReopened,
        ({ item }) => (
            <>
                <>
                    reopened a comment on <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Comment reopened</>
    ],
    [
        ActivityType.WorkflowCompleted,
        ({ item }) => (
            <>
                <>
                    completed <OptionalItemLink name="an item" {...item} />
                </>
            </>
        ),
        () => <>Completed</>
    ],
    [
        ActivityType.PackageCreated,
        () => (
            <>
                <>Created a package </>
            </>
        ),
        () => <>Created</>
    ],
    [
        ActivityType.PackageItemImported,
        ({ item }) => (
            <>
                <>
                    imported <OptionalItemLink name="an item" {...item} />{" "}
                </>
            </>
        ),
        ({ item }) => <>Imported</>
    ]
];

export default {
    activities: new Map(
        activities.map(([activityType, modifiedBy, subject, override]) => {
            return [activityType, { modifiedBy, subject, override }];
        })
    )
};
