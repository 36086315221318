export enum Permission {
    ManageUsers = 100,
    ManageIntegrations = 101,
    ManageRemoteAgent = 102,

    ReadBilling = 200,
    WriteBilling = 201,

    ListContent = 300,
    ReadContent = 301,
    WriteContent = 302,
    ApproveContent = 303,
    PublishContent = 304,
    ListDraftContent = 305,
    ReadDraftContent = 306,

    PerformTask = 402,
    ApproveTask = 403,
    AssignTask = 404,
    AcceptIssue = 405,
    UpdateIssue = 406,
    AssignIssue = 407,

    EditControl = 500,
    AssignControl = 501,
    EditStandard = 502,

    Audit = 600,
    ManageAudit = 601,

    ManageSpaces = 700,

    ReadComment = 800,
    WriteComment = 801,

    CreateClientEntity = 900,
    Consult = 901,
    PushClientPackage = 902,
    PushClientUser = 903,

    WritePackage = 1000,
    ReadPackage = 1001,
    ImportPackage = 1002,
    SelectPackage = 1003
}
