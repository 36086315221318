import { contexts } from "AppProvider";
import { useCallback, useContext } from "react";
import { Permission } from "shared/models/Permission";

type HasPermissionParams = {
    permissions: Permission[];
    spaceIds?: string[];
    matchCriteria?: "some" | "every";
};

export const useHasPermission = () => {
    const session = useContext(contexts).session;

    return useCallback(
        ({ permissions, spaceIds, matchCriteria = "some" }: HasPermissionParams) => {
            if (matchCriteria === "some") {
                return !permissions.length || permissions.some((p) => session?.hasPermission(p, spaceIds));
            }
            return permissions.every((p) => session?.hasPermission(p, spaceIds));
        },
        [session]
    );
};
